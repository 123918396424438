import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_pro.js";
import { Link } from "gatsby";
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`HTML iframe tags allow you to easily embed an interactive map on your website,
blog, or online-article.`}</p>
    <br />
    <p>{`Select the "Plugin" tab above the map and copy and paste the code and place it
where it should be shown on your website.`}</p>
    <br />
    <Image className="mx-auto w-3/4" src="pro/embed-tab.png" mdxType="Image" />
    <br />
    <p>{`The code above embeds this map`}</p>
    <br />
    {
      /* Trailguide plugin for Hafjell Trailbiking */
    }
    <iframe title="Hafjell Trailbiking" src="https://trailguide.net/x4e" width="100%" height="600px">
    </iframe>
    <br />
    <br />
    <br />
    <Link className="float-left" to="/pro/mapevent" mdxType="Link">
  ← Create a time limited map for events
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      